// AutoScrollMixin.js
// 滚动的相关代码

export const AutoScrollMixin = {
    data() {
        return {
            autoScroll: false, // 自动滚动开关
            autoTimer: null,
            scrollbar: null, // 引用 #scrollbar 元素
        }
    },
    watch: {
        isSendFlag(val) {
            // 是否在发送中 开启关闭自动滚动
            if (val) {
                this.startScrollingFn()
            } else {
                this.clearIntervalFn()
            }
        },
    },
    mounted() {
        this.scrollbar = document.getElementById('scrollbar')
        if (this.scrollbar) {
            this.scrollbar.addEventListener('scroll', this.handleScrollThrottled)
            // this.autoTimer = setInterval(this.handleAutoScrollThrottled, 150) // 每200毫秒触发一次自动滚动函数
        }
    },
    beforeDestroy() {
        if (this.scrollbar) {
            this.scrollbar.removeEventListener('scroll', this.handleScrollThrottled)
            this.clearIntervalFn()
        }
    },
    activated() {
        if (this.scrollbar) {
            // 组件被缓存激活时，重新添加滚动事件监听
            this.scrollbar.addEventListener('scroll', this.handleScrollThrottled)
        }
        this.toBottom()
    },
    deactivated() {
        // 组件被缓存停用时，移除滚动事件监听
        if (this.scrollbar) {
            this.scrollbar.removeEventListener('scroll', this.handleScrollThrottled)
            this.clearIntervalFn()
        }
    },
    methods: {
        // 启动定时滚动逻辑
        startScrollingFn() {
            this.autoTimer = setInterval(this.handleAutoScrollThrottled, 150) // 每200毫秒触发一次自动滚动函数
            this.autoScroll = true
        },
        // 清除定时滚动
        clearIntervalFn() {
            clearInterval(this.autoTimer)
        },
        handleScrollThrottled: throttle(function () {
            if (!this.scrollbar) return
            const scrollPosition = this.scrollbar.scrollTop // 获取滚动位置
            const viewportHeight = this.scrollbar.clientHeight // 获取窗口高度
            const contentHeight = this.scrollbar.scrollHeight // 获取文档总高度
            if (scrollPosition + viewportHeight >= contentHeight - 30) {
                this.autoScroll = true // 当滚动位置距离底部不足  30px 时，会开启自动滚动。
            } else {
                this.autoScroll = false // 否则停止自动滚动
            }
        }, 150),
        handleAutoScrollThrottled: throttle(function () {
            console.log('🚀 ~ file: AutoScrollMixin.js ~ line 43 ~ autoScroll', this.autoScroll)
            if (this.autoScroll) {
                if (this.scrollbar) {
                    // 将滚动位置设置为内容的最底部
                    this.scrollbar.scrollTop = this.scrollbar.scrollHeight
                }
            }

            if (this.autoScroll) {
                // 将滚动位置设置为页面的最底部
                window.scrollTo(0, document.body.scrollHeight)
            }
        }, 150),
        // 手动滚动到最底部
        toBottom() {
            setTimeout(() => {
                if (this.scrollbar) {
                    this.scrollbar.scrollTop = this.scrollbar.scrollHeight
                }
            }, 150)
        },
    },
}

// 使用节流函数，限制函数调用频率
function throttle(func, delay) {
    let timeoutId
    return function () {
        if (!timeoutId) {
            const context = this
            const args = arguments
            timeoutId = setTimeout(function () {
                func.apply(context, args)
                timeoutId = null
            }, delay)
        }
    }
}
