<template>
    <div class="chatGpt">
        <div class="gtpBox">
            <div class="contentBox">
                <div>
                    <header>
                        <div class="header-left">
                            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/gpt/ai.png" />
                            <span class="title">课代表</span>
                            <span class="tag">答案由AI生成 仅供参考</span>
                        </div>
                        <span @click="closeDrawer">
                            <svg-icon  icon-class="close"></svg-icon>
                        </span>
                    </header>
                </div>
                <div id="scrollbar">
                    <el-scrollbar class="chatListBox">
                        <div class="defaultQuestion" v-if="show_preset_question == 1">
                            <div class="header">其他用户都在问什么?</div>
                            <div class="ul">
                                <div class="li" v-for="(item, index) in questionRecommendList" :key="index" @click="changeRecommend(item.question)">
                                    <span>{{ item.question }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="chatList">
                            <MsgBox v-for="(item, index) in msgList" :key="index" :MsgInfo="item" :isSendFlag="isSendFlag"/>
                        </div>
                    </el-scrollbar>
                </div>
                <div class="chat-input-container" ref="chatInputBox">
                    <div class="functionBlock">
                        <!-- <div class="recommend">
                            <el-switch v-model="courseRecommendFlag" :disabled="isSendFlag" active-color="#1A72FF" inactive-color="#ff4949" />
                            <span>推荐课程</span>
                        </div> -->
                        <div class="clear" @click="clearContext">
                            <svg-icon icon-class="clear"></svg-icon>
                            <el-button type="text" :disabled="isSendFlag">清除历史记录</el-button>
                        </div>
                    </div>
                    <div class="chat-input-textarea">
                        <div class="chat-input">
                            <textarea
                                ref="inputTextarea"
                                v-model="message"
                                :disabled="isSendFlag"
                                @input="adjustTextareaHeight"
                                @keydown.enter="handleEnterKey"
                                @compositionstart="onCompositionStart"
                                @compositionend="onCompositionEnd"
                                placeholder="发送消息给AI..."
                            />
                            <div class="sendBox" @click="sendMessage">
                                <svg-icon icon-class="send" :className="isSendFlag?'':'active'"></svg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { fetchEventSource } from '@microsoft/fetch-event-source'
import MsgBox from '../components/MsgBox.vue'
import { AutoScrollMixin } from './AutoScrollMixin'
import URL from '@/config/url'

export default {
    components: { MsgBox },
    mixins: [AutoScrollMixin],
    data() {
        return {
            sn: '', // 唯一标识
            session_id: '',
            defaultQuestion: true, // 是否显示默认问题
            refreshing: false, // 下拉加载状态
            NoMore: false, // 是否没有历史消息了
            courseRecommendFlag: true, // 课程推荐开关
            courseList: [], // 推荐的课程list
            msgList: [], // 消息list
            questionRecommendList: [], // 推荐的话术
            message: '', // 发送的消息文本
            isSendFlag: false, // 是否正在发送消息
            compositiLock: false, // 中文输入法的锁-解决有拼音的时候 回车直接发送消息
            maxTextareaHeight: 180, // 文本区域的最大高度
            show_preset_question:0,//是否显示预设问题
        }
    },
    created() {
        const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}
        this.session_id = userInfo.phone
        this.getQuestionRecommend()
        this.showPresetQuestion()
    },
    mounted() {
        this.getHistory().then(() => {
            this.scrollbar.scrollTop = this.scrollbar.scrollHeight
        })
    },
    methods: {
        // 输入框的自适应函数
        adjustTextareaHeight(e) {
            this.$refs.inputTextarea.style.height = e.target.scrollHeight + 'px';
        },
        onCompositionStart() {
            this.compositiLock = true
        },
        onCompositionEnd() {
            this.compositiLock = false
        },
        handleEnterKey(event) {
            if (!event.shiftKey && !this.compositiLock) {
                event.preventDefault()
                this.sendMessage()
            }
        },
        // 发送消息
        async sendMessage() {
            this.$refs.inputTextarea.style.height = 'auto'
            const that = this
            if (this.message.trim() === '') return
            this.sn = Date.now() // 获取唯一标识
            this.toBottom()
            if (this.defaultQuestion) this.defaultQuestion = false
            this.isSendFlag = true // 正在发送消息
            const Msg = this.message // 存储一份数据
            this.message = ''
            const LastItem = {
                send_msg: Msg.replace(/\n/g, '<br>'),
                receive_msg: '',
                sn: that.sn, // 唯一标识
                aiLoading: true, // ai在思考中
                complete: false, // 数据是否接收完成
                error: false, // 是否有错误
            }
            this.msgList.push(LastItem)
            if (this.courseRecommendFlag) this.getCourseRecommend(Msg)
            that.ctrlAbout = new AbortController()
            fetchEventSource(`${URL.API}/ai/v1/chat_gpt`, {
                openWhenHidden: true, // 用于解决切屏会重复链接，导致消息会发送多次
                method: 'POST',
                headers: {
                    Accept: 'text/event-stream',
                    'Content-Type': 'application/json',
                    token:localStorage.getItem('token')
                },
                signal: that.ctrlAbout.signal,
                body: JSON.stringify({
                    sn: that.sn,
                    session_id: that.session_id,
                    query: Msg,
                    type:this.$route.meta.gptType || 1,//类型:1-首页全局bot 2-任务页bot
                    task_ucode:(this.$route.meta.gptType==2)?this.$route.query.ucode:undefined
                }),
                onopen(response) {
                    console.info('eventSource open: ', response)
                },
                onmessage(event) {
                    LastItem.aiLoading = false
                    // console.log('🚀 ~ file: index.vue ~ line 36 ~ onmessage ~ event', event, event.data)
                    if (event.data.includes('xmp1910:')) {
                        // 错误信息
                        LastItem.receive_msg = event.data.replace('xmp1910:', '')
                        LastItem.error = true
                        LastItem.complete = true
                        that.isSendFlag = false
                        that.ctrlAbout.abort()
                        return
                    } else if (event.data == 'xmp1911') {
                        // 正常接收完成
                        LastItem.complete = true
                        that.isSendFlag = false
                        // 成功之后满足某些条件可以使用AbortController关闭连接
                        that.toBottom() // 兜底接受完消息后，在最底部展示
                        that.ctrlAbout.abort()
                        return
                    }
                    // 利用对象地址引用 更新消息
                    LastItem.receive_msg += event.data.replace(/\\n/g, '<br>')
                },
            })
        },
        // 获取历史消息
        async getHistory() {
            return new Promise(resolve => {
                this.refreshing = true
                const params = {
                    sn: this.msgList[0] && this.msgList[0].sn,
                    session_id: this.session_id,
                    page_num: 1,
                    page_size: 10,
                    type:this.$route.meta.gptType || 1,//类型:1-首页全局bot 2-任务页bot
                    task_ucode:(this.$route.meta.gptType==2)?this.$route.query.ucode:undefined
                }
                this.$api.gpt.getHistory(params).then(res => {
                    if (res.data.list.length == 0) return (this.NoMore = true)
                    let arr = res.data.list.reverse()
                    this.msgList.unshift(...arr)
                    this.refreshing = false
                    resolve()
                })
            })
        },
        // 获取推荐课程
        async getCourseRecommend(Msg) {
            const res = await this.$api.gpt.courseRecommend({
                sn: this.sn,
                session_id: this.session_id,
                query: Msg,
            })
            this.$set(this.msgList[this.msgList.length - 1], 'courseList', res.data)
        },
        // 获取推荐问题
        getQuestionRecommend() {
            // const res = await this.$api.gpt.getQuestionRecommend()
            if(this.$route.meta.gptType){
                this.questionRecommendList = this.ConstData.gptTypeRecommend[+this.$route.meta.gptType]
            }
        },
        // 点击了推荐话术
        changeRecommend(question) {
            if (this.isSendFlag) return
            this.message = question
            this.defaultQuestion = false
            this.sendMessage()
            this.clickPresetQuestion();
        },
        // 点击了预设问题
        clickPresetQuestion(){
            this.$api.gpt.preset_question({
                type:this.$route.meta.gptType || 1,//类型:1-首页全局bot 2-任务页botx
                task_ucode:(this.$route.meta.gptType==2)?this.$route.query.ucode:undefined
            }).then(res => {
                if(res.code==0){
                    this.show_preset_question = 0;
                }
            })
        },
        // 是否显示预设问题
        showPresetQuestion(){
            this.$api.gpt.show_preset_question({
                type:this.$route.meta.gptType || 1,//类型:1-首页全局bot 2-任务页bot
                task_ucode:(this.$route.meta.gptType==2)?this.$route.query.ucode:undefined
            }).then(res => {
                if(res.data){
                    this.show_preset_question = res.data.status; //0:隐藏 1:显示
                }
            })
        },
        // 清除历史记录
        async clearContext() {
            const res = await this.$api.gpt.clearContext({
                type:this.$route.meta.gptType || 1,//类型:1-首页全局bot 2-任务页bot
                task_ucode:(this.$route.meta.gptType==2)?this.$route.query.ucode:undefined,
                session_id: this.session_id })
            if (res.message == 'success') {
                this.show_preset_question = 1 ;
                this.$message.success('清除成功')
                this.sn = ''
                this.msgList = []
            }
        },
        // 关闭对话框口
        closeDrawer(){
            this.$emit('openAi',false)
        },
    },
}
</script>

<style lang="scss" scoped>
.chatGpt {
    height:100%;
    position: relative;
    box-shadow: -2px 4px 8px 0px rgba(0, 0, 0, 0.08);
    border-left: 1px solid #E5E8EB;
    .gtpBox {
        background: #ffffff;
        height: 100%;
        margin: auto;
        display: flex;
        .contentBox {
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            header{
                height:48px;
                align-items: center;
                justify-content: space-between;
                display: flex;
                padding:0 16px;
                border-bottom: 1px solid #E5E8EB;
                .header-left{
                    align-items: center;
                    display: flex;
                }
                img{
                    width:24px;
                    height:24px;
                    margin-right:7px;
                }
                .title{
                    color:#333;
                    font-weight:600;
                    margin-right:16px;
                }
                .tag{
                    font-size: 12px;
                    color:#C0C4CC;
                }
                .icon{
                    color:#979797;
                    font-size:12px;
                }
            }
            #scrollbar {
                overflow-y: auto;
                flex-grow: 1;
                box-sizing: border-box;
                // display: flex;
                // flex-direction: column;
                // justify-content: flex-end;
                &::-webkit-scrollbar{
                    width: 3px;
                    background-color: #fff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    background-color: #fff;
                    -webkit-box-shadow: #fff;
                }
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    -webkit-box-shadow: #fff;
                }
                ::v-deep.el-scrollbar__wrap{
                    &::-webkit-scrollbar{
                        width: 10px;
                        height: 10px;
                        background-color: black;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: #fff;
                        -webkit-box-shadow: #fff;
                    }
                    &::-webkit-scrollbar-track {
                        border-radius: 10px;
                        -webkit-box-shadow: #fff;
                    }
                }
                .chatListBox {
                    box-sizing: border-box;
                    padding:20px 16px 0;
                    .defaultQuestion {
                        font-size: 14px;
                        line-height: 20px;
                        .header {
                            font-weight: 400;
                            color: #303133;
                            background:#F5F8FA;
                            margin-bottom:16px;
                            border-radius:0 8px 8px 8px;
                            padding:10px 16px;
                            display:inline-block;
                        }
                        .ul {
                            display: grid;
                            flex-wrap: wrap;
                            justify-content: end;
                            .li {
                                cursor: pointer;
                                margin-bottom: 16px;
                                color: #1A72FF;
                                text-align:right;
                                span{
                                    padding:10px 16px;
                                    border:1px solid #1A72FF;
                                    border-radius:8px 0 8px 8px;
                                    display:inline-block;
                                }
                            }
                        }
                    }
                }
            }
            .chat-input-container {
                background:#F4F5F7;
                padding:0 16px 16px;
                box-sizing: border-box;
                min-height: 184px;
                max-height:224px;
                .functionBlock {
                    height:48px;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    .recommend {
                        display: flex;
                        align-items: center;
                        span {
                            margin-left: 9px;
                            font-size: 14px;
                        }
                        color: #303133;
                    }
                    .clear {
                        font-size: 30px;
                        display:flex;
                        align-items:center;
                        &:hover{
                            cursor:pointer;
                            opacity:.8;
                        }
                        .icon{
                            margin-right:4px;
                        }
                        .el-button {
                            font-weight: 400;
                            color: #303133;
                        }
                    }
                }
                .chat-input {
                    border-radius: 8px;
                    box-sizing: border-box;
                    background: #fff;
                    overflow: hidden;
                    position: relative;
                    padding: 16px 16px 26px;
                    img {
                        width: 24px;
                        height: 24px;
                        vertical-align: middle;
                    }
                    textarea {
                        transition: all .3s ease; 
                        width:100%;
                        box-sizing: border-box;
                        border: none;
                        resize: none;
                        font-size: 14px;
                        overflow: hidden;
                        line-height: 20px;
                        min-height: 68px;
                        max-height: 128px; /* 文本区域的最大高度 */
                        background: #fff;
                        font-weight: 400;
                        color: #303133;
                        &:focus {
                            border: none !important;
                            box-shadow: none !important;
                        }
                    }
                    .sendBox{
                        position: absolute;
                        right:12px;
                        left:0;
                        text-align: right;
                        bottom:6px;
                        z-index: 9;
                        background:#fff;
                        .icon{
                            cursor: pointer;
                            color:#A1A7B2;
                        }
                        .active{
                            color:#1A72FF;
                        }

                    }
                }
            }
        }
    }
}
</style>
