<template>
    <div class="MsgBox">
        <div class="msg" :class="position">
            <div class="content">
                <div class="textBox">
                    <div class="gptText">
                        <div v-if="MsgInfo.aiLoading" class="aiLoading">
                            正在努力思考，请耐心等待
                            <span>...</span>
                        </div>
                        <div v-else>
                        <!-- 'reply-evaluate' -->
                            <div :class="getEvaluateCls()" class="reply-evaluate" v-if="!isSendFlag && position == 'left'">
                                <template v-if="MsgInfo.reaction==1 || MsgInfo.reaction==2">
                                    <span>
                                        <svg-icon icon-class="approvaled" v-if="MsgInfo.reaction===1"></svg-icon>
                                        <svg-icon icon-class="opposeed" v-else></svg-icon>
                                    </span>
                                </template>
                                <template v-else>
                                    <span @click="toEvaluate(1)">
                                        <svg-icon icon-class="approval"></svg-icon>
                                    </span>
                                    <span class="line"></span>
                                    <span @click="toEvaluate(2)">
                                        <svg-icon icon-class="oppose"></svg-icon>
                                    </span>
                                </template>
                            </div>
                             <div v-html="content" :class="[flashLockBlock, MsgInfo.error ? 'red' : '']"></div>
                        </div>
                    </div>
                    <template v-if="position == 'left' && !MsgInfo.error">
                        <div class="courss" v-if="MsgInfo.complete && MsgInfo.courseList?.length > 0">
                            <div class="header">以下是相关课程推荐：</div>
                            <div class="ul">
                                <div class="li" v-for="(item, index) in MsgInfo.courseList" :key="index" @click="jumpCourse(item)">
                                    <img :src="item.coverUrl" class="img" alt="" />
                                    <div class="bottom">
                                        <div class="titile">{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'msg',
    props: {
        // 位置 left/right
        position: {
            type: String,
            required: true,
            default: 'right',
        },
        // 内容
        content: {
            type: String,
            required: true,
            default: '',
        },
        // 是否在发送消息中
        isSendFlag:{
            type:Boolean,
            default:false
        },
        // // 头像
        // avatar: {
        //     type: String,
        //     // default: 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/default-avatar.png',
        // },
        MsgInfo: {
            type: Object,
            default() {
                return {
                    error: false, // 错误
                    complete: false, // 数据接收完成
                    aiLoading: false, // 是否在思考问题
                    courseList: [], // 推荐的课程
                }
            },
        },
    },
    computed: {
        flashLockBlock() {
            // 是否展示闪烁块
            if (this.position == 'left' && this.MsgInfo.complete == false) {
                return 'blink-container'
            } else {
                return ''
            }
        },
    },
    methods: {
        jumpCourse(item) {
            this.$router.push({
                path: '/course/detail',
                query: {
                    ucode: item.course_ucode,
                },
            })
        },
        // 回答赞踩
        toEvaluate(reaction){
            if(this.MsgInfo.reaction===1 || this.MsgInfo.reaction===2) return
            const data = {
                sn:this.MsgInfo.sn,
                reaction:reaction, //0：未评价，1:赞 ，2：踩
                feedback:'',
            }
            this.$api.gpt.evaluate_llm(data).then(res=>{
                if(res.code==0){
                    this.$set(this.MsgInfo,'reaction',reaction)
                }
            })
        },
        // 评价
        getEvaluateCls(){
             let str = '';
            if(this.MsgInfo.reaction==1 || this.MsgInfo.reaction==2){
                str = 'evaluateed'
            }
            return str
        }
    },
}
</script>

<style lang="scss" scoped>
.MsgBox {
    padding-bottom: 16px;

    .msg {
        display: flex;
        align-items: flex-start;
        .content {
            padding:10px 16px;
            background: #2e7fff;
            display: flex;
            align-items: center;
            &:hover{
                .textBox .gptText .reply-evaluate{
                    display:flex;
                }
            }
            .textBox {
                font-size: 14px;
                font-weight: 400;
                .gptText {
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                    div{
                        word-break: break-word;
                    }
                    .reply-evaluate{
                        position:absolute;
                        bottom:-28px;
                        right:0;
                        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05);
                        background:#fff;
                        border-radius:4px;
                        padding:7px 0px;
                        width:74px;
                        box-sizing:border-box;
                        display:none ;
                        align-items:center;
                        span{
                            flex:1;
                            line-height:16px;
                            text-align:center;
                        }
                        .line{
                            width:1px;
                            height:8px;
                            flex:none;
                            background:#D8D8D8;
                        }
                        .icon{
                            font-size:16px;
                            &:hover{
                                color:#1A72FF;
                            }
                        }
                    }
                    .evaluateed{
                        width:33px;
                        height:33px;
                        padding:0;
                        border-radius:50%;
                        background:transparent;
                        box-shadow:none;
                        .icon{
                            font-size:33px;
                        }
                    }
                    .aiLoading {
                        display: flex;
                        align-items: center;
                        span {
                            height: 1em;
                            display: flex;
                            flex-direction: column;
                            line-height: 1;
                            overflow: hidden;
                            &::before {
                                content: '...\a..\a.';
                                white-space: pre-wrap;
                                animation: ellipsis 2s infinite step-end;
                            }
                        }
                    }
                    .red {
                        color: red;
                    }
                    @keyframes ellipsis {
                        33% {
                            transform: translateY(-2em);
                        }

                        66% {
                            transform: translateY(-1em);
                        }
                    }
                }
                .courss {
                    margin-top: 60px;
                    .header {
                        color: #303133;
                        font-size: 14px;
                        margin-bottom: 24px;
                    }
                    .ul {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr); /* 将容器分成3列，每列平均占据剩余空间 */
                        grid-row-gap: 24px; // 行间距
                        grid-column-gap: 32px; // 列间距。
                        .li {
                            cursor: pointer;
                            .img {
                                border-radius: 8px 8px 0px 0px;
                                width: 268px;
                                height: 151px;
                                display: block;
                            }
                            .bottom {
                                padding: 12px 16px 24px 16px;
                                box-sizing: border-box;
                                width: 268px;
                                height: 80px;
                                background: #ffffff;
                                border-radius: 0px 0px 8px 8px;
                                box-shadow: 0px 4px 16px 0px rgba(34, 98, 188, 0.1);
                                .titile {
                                    white-space: normal;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    width: 236px;
                                    font-size: 16px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 22px;
                                    text-shadow: 0px 4px 16px rgba(34, 98, 188, 0.1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .left {
        margin-bottom:12px;
        .content {
            padding:8px 15px 16px;
            border-radius: 0px 8px 8px 8px;
            .gptText {
                color: #303133 !important;
                line-height: 24px;
                position:relative;
            }
        }
    }
    .right {
        flex-direction: row-reverse;
        .content {
            padding:10px 16px;
            line-height: 20px;
            background: #2e7fff;
            border-radius: 8px 0px 8px 8px;
        }
    }
}
// 闪烁快动画
.blink-container:after {
    content: '▋';
    margin-left: 8px;
    animation: blinkAnimation 1s infinite;
}

@keyframes blinkAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
</style>
